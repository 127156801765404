import React, { FC, useEffect, useState, useRef } from 'react';
import { Modal } from '@consta/uikit/Modal';
import {
  Table,
  TableFilterContainer,
  TableFilters,
  TableNumberFilter,
  TableChoiceGroupFilter,
} from '@consta/uikit/Table';
import { TextField } from '@consta/uikit/TextField';
import { PackagesListFilterInput, PackagesListOrderInput, PaymentType, PackageType, Maybe } from 'types';
import { ModalContent } from 'pages/packages/ui/transferModal/styled';
import { useEditPackageMutation } from 'pages/packages/api/editPackage.generated';
import { usePackagesListQuery } from 'pages/packages/api/packagesList.generated';
import { TableCellPadding } from 'pages/admin/boxes/styled';
import { useIsInViewport } from 'shared/hooks/useIsInViewport';
import { paymentTypes } from 'shared/constants';
import TableInput from 'shared/ui/components/tableInput';

import '../../../table.css';
import './modal.css';

interface dataFormProps {
  shipmentId: number,
  userId: number,
  isOpen: boolean,
  onClose: () => void,
};

const PackagesModal: FC<dataFormProps> = ({ shipmentId, userId, isOpen, onClose }: dataFormProps) => {
  const pageSize = 20;
  const [offset, setOffset] = useState(0);

  const [filter, setFilter] = useState<Omit<PackagesListFilterInput, 'shipmentId'>>({});
  const [filterCopy, setFilterCopy] = useState<Omit<PackagesListFilterInput, 'shipmentId'>>({});
  const [sortOrder, setSortOrder] = useState<PackagesListOrderInput | undefined>();

  const [packages, setPackages] = useState<any[]>([]);
  const [total, setTotal] = useState(0);

  const footerRef = useRef(document.getElementById('modal-footer'));
  const inViewport = useIsInViewport(footerRef);

  const { data, refetch } = usePackagesListQuery({
    skip: typeof shipmentId !== 'number' || typeof userId !== 'number',
    variables: {
      filter: {
        shipmentId,
        userId,
      },
      pagination: {},
    },
  });

  useEffect(() => {
    if (data?.packagesList?.items) {
      if (offset) {
        setPackages([...packages, ...data.packagesList.items]);
      } else {
        setPackages(data.packagesList.items);
        setTotal(data.packagesList.total);
      }
    }
  }, [data]);

  useEffect(() => {
    if (inViewport && ((offset + 1) * pageSize <= total)) {
      setOffset(offset + 1);
    }
  }, [inViewport]);

  useEffect(() => {
    updateList();
  }, [filter, offset, sortOrder]);

  const [editingPackageId, setEditingPackageId] = useState<number | undefined>(-1);
  const [editingField, setEditingField] = useState<'deliveryRate' | 'comment' | undefined>();

  const [editPackage, {data: dataEdit, error: errorEdit}] = useEditPackageMutation();

  const updateList = () => {
    refetch({
      filter: {
        shipmentId,
        userId,
        ...(filter || {}),
      },
      pagination: {
        limit: pageSize,
        offset: offset * pageSize,
        order: sortOrder || {},
      },
    });
  };

  const saveChanges = async (value: string | null) => {
    const deliveryRate = value ? parseFloat(value) : NaN;

    if (typeof editingPackageId === 'number') {
      await editPackage({
        variables: {
          id: editingPackageId,
          input: {
            deliveryRate: editingField === 'deliveryRate' && !isNaN(deliveryRate) ? deliveryRate : undefined,
            comment: editingField === 'comment' ? value : undefined,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (dataEdit?.editPackage && !errorEdit) {
      setEditingPackageId(undefined);
      setEditingField(undefined);

      refetch({
        filter: {
          shipmentId,
          userId,
          ...(filter || {}),
        },
        pagination: {
          limit: packages.length,
          order: sortOrder,
        },
      });
    }
  }, [dataEdit, errorEdit]);

  const columns = [
    {
      title: 'ТРЕК-НОМЕР МАГАЗИНА',
      accessor: 'track',
      sortable: true,
    },
    {
      title: 'ТРЕК-НОМЕР UCS',
      accessor: 'id',
      sortable: true,
    },
    {
      title: 'ВЕС',
      accessor: 'clientWeight',
      sortable: true,
    },
    {
      title: 'СТРАХОВКА',
      accessor: 'insuredPrice',
      sortable: true,
    },
    {
      title: 'ТАРИФ',
      accessor: 'deliveryRate',
      sortable: true,
      renderCell: ({ id, deliveryRate }: PackageType) => (
        <TableInput
          inputValue={deliveryRate?.toString()}
          disabled={id !== editingPackageId || editingField !== 'deliveryRate'}
          onClear={() => setEditingPackageId(undefined)}
          onSave={saveChanges}
          onClickEdit={() => {
            setEditingPackageId(id);
            setEditingField('deliveryRate');
          }}
          onBlur={() => {
            setEditingPackageId(undefined);
            setEditingField(undefined);
          }}
          small
          number
        />
      ),
    },
    {
      title: 'СУММА $',
      accessor: 'costUsd',
      sortable: true,
      renderCell: ({ totalCost }: PackageType) => (
        <>{ totalCost?.costUsd }</>
      ),
    },
    {
      title: 'СУММА РУБ',
      accessor: 'costRub',
      sortable: true,
      renderCell: ({ totalCost }: PackageType) => (
        <>{ totalCost?.costRub }</>
      ),
    },
    {
      title: 'СПОСОБ ОПЛАТЫ',
      accessor: 'paymentType',
      sortable: false,
      renderCell: ({ order }: PackageType) => order?.paymentType && (
        <>{ paymentTypes[order.paymentType] }</>
      ),
    },
    {
      title: 'КОММЕНТАРИЙ',
      accessor: 'comment',
      sortable: false,
      renderCell: ({ id, comment }: PackageType) => (
        <TableInput
          inputValue={comment}
          disabled={id !== editingPackageId || editingField !== 'comment'}
          onClear={() => setEditingPackageId(undefined)}
          onSave={saveChanges}
          onClickEdit={() => {
            setEditingPackageId(id);
            setEditingField('comment');
          }}
          onBlur={() => {
            setEditingPackageId(undefined);
            setEditingField(undefined);
          }}
          small
        />
      ),
    },
  ];

  const handleSortingUpdated = (value) => {
    setOffset(0);

    let sortVal: string = value?.sortingBy;

    if (['costUsd', 'costRub'].includes(value?.sortingBy)) {
      sortVal = 'deliveryCost';
    }

    if (sortVal) {
      const sort = {
        [sortVal]: value?.sortOrder.toUpperCase(),
      };
      setSortOrder(sort);
    } else {
      setSortOrder(undefined);
    }
  };

  const handleFilterUpdated = (value: any) => {
    setOffset(0);

    const filtersObj = { ...filter };
    
    Object.keys(value).forEach(item => {
      if (value[item]?.selected?.length) {
        if (item === 'deliveryRate') {
          filtersObj[item] = {
            min: value[item]?.value?.min ? parseFloat(value[item]?.value?.min) : undefined,
            max: value[item]?.value?.max ? parseFloat(value[item]?.value?.max) : undefined,
          };
        } else if (item === 'paymentType') {
          filtersObj[item] = value[item]?.value?.value;
        } else {
          filtersObj[item] = filterCopy[item];
        }
      }

      if (filter && filter[item] && !value[item]?.selected?.length) {
        filtersObj[item] = undefined;
      }
    });

    setFilter(filtersObj);
    setFilterCopy(filtersObj);
  };

  const filters: TableFilters<any> = [
    {
      id: 'track',
      name: `Трек-номер магазина: ${filter?.track}`,
      field: 'track',
      filterer: () => true,
      component: {
        name: TableFilterContainer as any,
        props: {
          children: (
            <TextField
              value={filterCopy?.track}
              onChange={({ value }) => setFilterCopy({ ...filterCopy, track: value })}
              width='full'
            />
          ),
        },
      },
    },
    {
      id: 'deliveryRate',
      name: 'Тариф: ',
      field: 'deliveryRate',
      filterer: () => true,
      component: {
        name: TableNumberFilter,
      },
    },
    {
      id: 'paymentType',
      name: 'Тип оплаты ',
      field: 'paymentType',
      filterer: () => true,
      component: {
        name: TableChoiceGroupFilter,
        props: {
          items: [
            {name: paymentTypes[PaymentType.BankTransfer], value: PaymentType.BankTransfer },
            {name: paymentTypes[PaymentType.PaymentGateway], value: PaymentType.PaymentGateway },
            {name: paymentTypes[PaymentType.RubCash], value: PaymentType.RubCash },
            {name: paymentTypes[PaymentType.UsdCash], value: PaymentType.UsdCash },
          ],
        },
      },
    },
  ];
  
  return (
    <Modal
      isOpen={isOpen}
      hasOverlay
      onClickOutside={onClose}
      onEsc={onClose}
      rootClassName='financeModal'
    >
      <ModalContent>
        <TableCellPadding>
          {/* @ts-ignore */}
          <Table rows={packages} columns={columns} filters={filters}
            verticalAlign='center'
            borderBetweenColumns
            zebraStriped='odd'
            defaultExpandAll
            onSortBy={(value) => handleSortingUpdated(value)}
            onFiltersUpdated={(value) => handleFilterUpdated(value)}
          />
        </TableCellPadding>
      </ModalContent>
      <div id='modal-footer' style={{ minHeight: 50 }} />
    </Modal>
  );
};

export default PackagesModal;