import React, { FC, useState, useEffect } from 'react';
import { TextField } from '@consta/uikit/TextField';
import { Button } from '@consta/uikit/Button';
import { IconEdit } from '@consta/uikit/IconEdit';
import { IconClose } from '@consta/uikit/IconClose';
import { IconCheck } from '@consta/uikit/IconCheck';
import { ShipmentType, PaymentType, Maybe } from 'types';
import { useUpdateShipmentMutation } from 'pages/admin/statuses/api/updateShipment.generated';
import { useGetShipmentQuery } from '../../api/getShipment.generated';
import { ShipmentInfoContainer } from './styled';

interface dataFormProps {
  shipmentId: number,
  onChange: () => void,
};

const ShipmentInfo: FC<dataFormProps> = ({ shipmentId, onChange }: dataFormProps) => {
  const [shipment, setShipment] = useState<ShipmentType | undefined>();
  const [editCurrency, setEditCurrency] = useState(false);
  const [currency, setCurrency] = useState<Maybe<number> | undefined>();

  const { data } = useGetShipmentQuery(
    {
      variables: {
        id: shipmentId,
      },
    },
  );

  const [updateShipment, {data: dataEdit, error}] = useUpdateShipmentMutation();

  useEffect(() => {
    if (data?.getShipment?.id) {
      setShipment(data.getShipment);
      setCurrency(data.getShipment.currencyRate);
    }
  }, [data]);

  const onSave = () => {
    if (typeof currency === 'number') {
      updateShipment({
        variables: {
          id: shipmentId,
          input: {
            currencyRate: currency,
          },
        },
      }).then(() => onChange());
    }
  };

  useEffect(() => {
    if (dataEdit?.updateShipment && !error) {
      setShipment(dataEdit.updateShipment);
      setCurrency(dataEdit.updateShipment.currencyRate);
      setEditCurrency(false);
    }
  }, [dataEdit, error]);

  return (
    <ShipmentInfoContainer>
      <div className='column'>
        <div className='item'>
          <div>Вес всей партии США</div>
          <div>{shipment?.summary?.shipmentWeight}</div>
        </div>
      </div>
      <div className='column'>
        <div className='item'>
          <div>Вес к выставлению</div>
          <div>{shipment?.summary?.totalClientWeigh}</div>
        </div>
        <div className='item'>
          <div>Количество посылок</div>
          <div>{shipment?.summary?.totalPackages}</div>
        </div>
      </div>
      <div className='column'>
        <div className='item'>
          <div>К оплате $</div>
          <div>{shipment?.summary?.totalCost?.costUsd}</div>
        </div>
        <div className='item'>
          <div>К оплате рубли</div>
          <div>{shipment?.summary?.totalCost?.costRub}</div>
        </div>
      </div>
      <div className='column'>
        <div className='item'>
          <div>Рубли карта</div>
          <div>
            {shipment?.summary?.paymentSummary.find(item => item.paymentType === PaymentType.BankTransfer)?.total}
          </div>
        </div>
        <div className='item'>
          <div>Робокасса</div>
          <div>
            {shipment?.summary?.paymentSummary.find(item => item.paymentType === PaymentType.PaymentGateway)?.total}
          </div>
        </div>
      </div>
      <div className='column'>
        <div className='item'>
          <div>Наличные $</div>
          <div>
            {shipment?.summary?.paymentSummary.find(item => item.paymentType === PaymentType.UsdCash)?.total}
          </div>
        </div>
        <div className='item'>
          <div>Наличные рубли</div>
          <div>
            {shipment?.summary?.paymentSummary.find(item => item.paymentType === PaymentType.RubCash)?.total}
          </div>
        </div>
      </div>
      <div className='column'>
        <div className='item'>
          <div>Курс доллара</div>
          <TextField
            type='number'
            disabled={!editCurrency}
            value={currency?.toString()}
            onChange={({ value }) => setCurrency(value ? parseFloat(value) : undefined)}
            placeholder='Введите курс'
            incrementButtons={false}
          />
          {editCurrency && (
            <>
              <Button
                label='Стереть'
                view='clear'
                iconLeft={IconClose}
                onlyIcon
                className='tableInputButton'
                size='s'
                onClick={() => setCurrency(undefined)}
              />
              <Button
                label='Сохранить'
                view='clear'
                disabled={!currency}
                iconLeft={IconCheck}
                onlyIcon
                className='tableInputButton'
                size='s'
                onClick={onSave}
              />
            </>
          )}
          {!editCurrency && <Button
            label='Редактировать'
            view='clear'
            iconLeft={IconEdit}
            onlyIcon
            size='s'
            onClick={() => setEditCurrency(true)}
          />}
        </div>
      </div>
    </ShipmentInfoContainer>
  );
};

export default ShipmentInfo;
