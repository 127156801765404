import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { Theme, presetGpnDark } from '@consta/uikit/Theme';
import { PageContainer } from '../layout/styled';
import Header from './header';
import { ContentContainer } from './styled';

const AdminLayout: FC = () => {
  return (
    <Theme preset={presetGpnDark}>
      <PageContainer>
        <Header />
        <ContentContainer>
          <Outlet />
        </ContentContainer>
        <div id='footer' style={{ minHeight: 50 }} />
      </PageContainer>
    </Theme>
  );
};

export default AdminLayout;