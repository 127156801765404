import React, { FC, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ContextMenu } from '@consta/uikit/ContextMenu';
import { User } from '@consta/uikit/User';
import { useAdmin } from 'entities/admin';
import { removeAuthToken } from 'shared/api/auth';

interface ContextMenuItem {
  title: string,
  callback?: () => void,
};

const HeaderUser: FC = () => {
  const navigate = useNavigate();
  const [showUserMenu, setShowUserMenu] = useState(false);

  const { admin } = useAdmin();

  const ref = useRef(null);

  const handleLogout = () => {
    removeAuthToken();
    setShowUserMenu(false);
    navigate('/login');
  };

  const menu: ContextMenuItem[] = [
    {
      title: 'Выйти',
      callback: handleLogout,
    },
  ];

  return (
    <>
      <div ref={ref} onClick={() => setShowUserMenu(!showUserMenu)}>
        <User name='Администратор' withArrow />
      </div>
      <ContextMenu
        isOpen={showUserMenu}
        items={menu}
        getItemLabel={(item: ContextMenuItem) => item.title} anchorRef={ref}
        getItemOnClick={(item: ContextMenuItem) => item.callback}
        direction='downCenter'
      />
    </>
  );
};

export default HeaderUser;