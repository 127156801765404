import { RouteObject, Navigate } from 'react-router-dom';
import Page404 from 'pages/page404';
import Login from 'pages/admin/login';
import Users from 'pages/admin/users';
import Boxes from 'pages/admin/boxes';
import Statuses from 'pages/admin/statuses';
import Reconciliation from 'pages/admin/reconciliation';
import Finance from 'pages/admin/finance';
import Packages from 'pages/admin/packages';
import Orders from 'pages/admin/orders';
import { RequireAuth, RequireNotAuth } from 'shared/auth/admin';
import AdminLayout from 'shared/ui/adminLayout';

export const routes: RouteObject[] = [
  {
    path: '/login',
    element: (
      <RequireNotAuth>
        <Login />
      </RequireNotAuth>
    ),
  },
  {
    path: '/',
    element: <AdminLayout />,
    children: [
      {
        index: true,
        element: <Navigate to={'statuses'} replace />,
      },
      {
        path: 'statuses',
        element: (
          <RequireAuth>
            <Statuses/>
          </RequireAuth>
        ),
      },
      {
        path: 'finance',
        element: (
          <RequireAuth>
            <Finance />
          </RequireAuth>
        ),
      },
      {
        path: 'orders',
        element: (
          <RequireAuth>
            <Orders />
          </RequireAuth>
        ),
      },
      {
        path: 'formation',
        element: (
          <RequireAuth>
            <Boxes />
          </RequireAuth>
        ),
      },
      {
        path: 'reconciliation',
        element: (
          <RequireAuth>
            <Reconciliation/>
          </RequireAuth>
        ),
      },
      {
        path: 'users',
        element: (
          <RequireAuth>
            <Users />
          </RequireAuth>
        ),
      },
      {
        path: 'admin-packages',
        element: (
          <RequireAuth>
            <Packages />
          </RequireAuth>
        ),
      },
      {
        path: '*',
        element: (
          <RequireAuth>
            <Page404 />
          </RequireAuth>
        ),
      },
    ],
  },
];
