import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { Menu } from '@consta/header/Menu';
import { ReactComponent as Logo } from 'shared/assets/images/logo.svg';
import Margin from 'shared/ui/components/margin';
import { MenuBlock } from './styled';

const HeaderMenu: FC = () => {
  const location = useLocation();

  const menu = [
    {
      label: 'Статусы',
      href: '/statuses',
      active: location?.pathname === '/statuses',
    },
    {
      label: 'Формирование',
      href: '/formation',
      active: location?.pathname === '/formation',
    },
    {
      label: 'Сверка',
      href: '/reconciliation',
      active: location?.pathname === '/reconciliation',
    },
    {
      label: 'Финансы',
      href: '/finance',
      active: location?.pathname === '/finance',
    },
    {
      label: 'Заказы',
      href: '/orders',
      active: location?.pathname === '/orders',
    },
    {
      label: 'Пользователи',
      href: '/users',
      active: location?.pathname === '/users',
    },
    {
      label: 'Посылки',
      href: '/admin-packages',
      active: location?.pathname === '/admin-packages',
    },
  ];

  return (
    <>
      <Margin mr={32}>
        <Logo />
      </Margin>
      <MenuBlock>
        <Menu items={menu} />
      </MenuBlock>
    </>
  );
};

export default HeaderMenu;