import React, { FC, useEffect, useState } from 'react';
import { Button } from '@consta/uikit/Button';
import { TextField, TextFieldOnChangeArguments } from '@consta/uikit/TextField';
import { FilterComponentProps } from '@consta/uikit/__internal__/src/components/Table/filtering';
import { IconSearch } from '@consta/uikit/IconSearch';
import { Maybe } from 'types';
import { CustomFilterWrapper, ButtonsWrapper } from './styled';

type TableTextFilterProps = FilterComponentProps & {
  inputRegexp?: RegExp
  placeholder?: string
  onFilter?: (value: Maybe<string>) => void
};

const CustomInputTableFilter: FC<TableTextFilterProps> = ({
  placeholder,
  inputRegexp,
  onFilter,
  onCancel,
  onConfirm,
}) => {
  const [value, setValue] = useState<Maybe<string>>(null);

  const handleChange = ({ value: textValue }: TextFieldOnChangeArguments) => {
    if (!textValue || inputRegexp && inputRegexp.test(textValue) || !inputRegexp) setValue(textValue);
  };

  const handleFilter = () => {
    onFilter?.(value);
    if (value?.length) onConfirm(value);
  };

  return (
    <CustomFilterWrapper>
      <TextField
        onChange={handleChange}
        value={value}
        leftSide={IconSearch}
        placeholder={placeholder}
        width='full'
      />
      <ButtonsWrapper>
        <Button label='Отмена' onClick={onCancel} size='s' view='ghost'/>
        <Button label='Применить' size='s' disabled={!value?.length} onClick={handleFilter}/>
      </ButtonsWrapper>
    </CustomFilterWrapper>
  );
};

export default CustomInputTableFilter;
