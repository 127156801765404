import React, { FC, useRef, useState } from 'react';
import { IconSearch } from '@consta/uikit/IconSearch';
import { TextField, TextFieldOnChangeArguments } from '@consta/uikit/TextField';
import { Maybe } from 'types';
import AdminTabs from 'shared/ui/components/adminTabs';
import { InfoWrapper, TitleWrapper } from './ui/styled';
import ReconciliationTable from './ui/table/reconciliationTable';
import { useGetShipmentQuery } from './api/getShipment.generated';

const Reconciliation: FC = () => {
  const [trackInput, setTrackInput] = useState<Maybe<string>>(null);
  const [trackSearch, setTrackSearch] = useState<Maybe<string>>(null);
  const [activeShipment, setActiveShipment] = useState<number | undefined>();
  const [activeField, setActiveField] = useState<string | undefined>();
  const searchInputRef = useRef<HTMLDivElement>(null);

  const onChangeShipment = (value: number) => {
    setActiveShipment(value);
  };

  const { data: dataShipment, refetch: updateSummary } = useGetShipmentQuery({
    skip: typeof activeShipment !== 'number',
    variables: {
      id: activeShipment as number,
    },
  });

  const handleTrackSearchChange = ({ value }: TextFieldOnChangeArguments) => {
    setActiveField(undefined);
    setTrackInput(value?.length ? value : null);
  };

  const handleTrackSearchKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      setTrackSearch(trackInput);
      setActiveField('weight');
    }
  };

  const getTrackClass = () => trackSearch === trackInput && trackSearch ? 'track_search' : '';

  const handleUpdateSummary = () => updateSummary();

  return (
    <>
      <TitleWrapper>
        <h1>Сверка веса</h1>
        <TextField
          ref={searchInputRef}
          className={getTrackClass()}
          value={trackInput}
          onChange={handleTrackSearchChange}
          onKeyDown={handleTrackSearchKeyDown}
          form='round'
          placeholder='Трек-номер'
          size='s'
          leftSide={IconSearch}
        />
      </TitleWrapper>
      <AdminTabs onChange={onChangeShipment} />
      <InfoWrapper>
        <div>
          <p>Вес всех посылок США</p>
          <p className='info_value'>
            {dataShipment?.getShipment.summary.totalApproximateWeigh}
          </p>
        </div>
        <div>
          <p>Вес всех посылок фактический</p>
          <p className='info_value'>
            {dataShipment?.getShipment.summary.totalActualWeigh}
          </p>
        </div>
        <div>
          <p>Количество посылок, которое вылетело</p>
          <p className='info_value'>
            {dataShipment?.getShipment.summary.totalPackages}
          </p>
        </div>
        <div>
          <p>Количество принятых посылок</p>
          <p className='info_value'>
            {dataShipment?.getShipment.summary.totalWeightedPackages}
          </p>
        </div>
      </InfoWrapper>
      <ReconciliationTable
        trackSearch={trackSearch ?? undefined}
        shipmentId={activeShipment ?? undefined}
        updateSummary={handleUpdateSummary}
        activeField={activeField}
        onChangeComment={() => searchInputRef?.current?.querySelector<HTMLInputElement>('input')?.focus()}
      />
    </>
  );
};

export default Reconciliation;
