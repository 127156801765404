import React, { FC, useEffect, useRef, useState } from 'react';
import { SortByProps, Table, TableFilters } from '@consta/uikit/Table';
import { SelectedFilters } from '@consta/uikit/__internal__/src/components/Table/filtering';
import { DeliveryStatus, Maybe, ShipmentType, SortEnum } from 'types';
import { statusesLabels } from 'shared/constants';
import { useIsInViewport } from 'shared/hooks/useIsInViewport';
import CustomInputTableFilter from 'shared/ui/components/customInputTableFilter';
import { useShipmentsListQuery } from '../boxes/api/shipmentsList.generated';
import { Title, TableWrapper } from './ui/styled';
import StatusSelect from './ui/statusSelect';

const StatusesPage: FC = () => {
  const pageSize = 40;
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);

  const [rows, setRows] = useState<any[]>([]);
  const [statusFilter, setStatusFilter] = useState<Maybe<DeliveryStatus>>(null);
  const [shipmentIdFilter, setShipmentIdFilter] = useState<Maybe<Maybe<string>>>(null);
  const [sortOrder, setSortOrder] = useState<Maybe<SortEnum>>(null);

  const [shipments, setShipments] = useState<ShipmentType[]>([]);

  const footerRef = useRef(document.getElementById('footer'));
  const inViewport = useIsInViewport(footerRef);

  const { data: dataShipments, refetch } = useShipmentsListQuery({
    variables: {
      filter: statusFilter || shipmentIdFilter
        ? { status: statusFilter, id: shipmentIdFilter ? +shipmentIdFilter : null }
        : {},
      pagination: {
        limit: pageSize,
        offset: offset * pageSize,
        order: sortOrder ? {id: sortOrder} : { createdAt: SortEnum.Desc },
      },
    },
  });

  useEffect(() => {
    if (inViewport && ((offset + 1) * pageSize <= total)) {
      setOffset(offset + 1);
    }
  }, [inViewport]);

  useEffect(() => {
    if (dataShipments?.shipmentsList?.items) {
      let currentShipments: ShipmentType[] = [];
      if (offset) {
        const newShipments = [...dataShipments?.shipmentsList?.items]
          .filter(shipment => !shipments.find(item => shipment.id === item.id));
        currentShipments = [...shipments, ...newShipments] as ShipmentType[];
        setShipments(currentShipments);
      } else {
        currentShipments = dataShipments?.shipmentsList?.items as ShipmentType[];
        setShipments(currentShipments);
        setTotal(dataShipments?.shipmentsList?.total);
      }
      setRowsFromShipments(currentShipments);
    }
  }, [dataShipments]);

  useEffect(() => {
    refetch();
  }, [statusFilter, shipmentIdFilter, sortOrder]);

  const columns = [
    {
      title: 'НОМЕР ПАРТИИ',
      accessor: 'number',
      sortable: true,
      width: 280,
    },
    {
      title: 'СТАТУС',
      accessor: 'status',
      sortable: false,
      withoutPadding: true,
      width: 280,
    },
  ];

  const statusFilters: TableFilters<typeof rows[number]> = Object.values(DeliveryStatus).map((status, idx) => ({
    id: idx.toString(),
    name: statusesLabels[status],
    filterer: () => true,
    field: 'status',
  }));

  const shipmentIdFilters: TableFilters<typeof rows[number]> = [{
    id: 'number',
    name: 'Номер партии: ' + shipmentIdFilter,
    filterer: () => true,
    field: 'number',
    component: {
      name: CustomInputTableFilter,
      props: {
        placeholder: 'Номер партии',
        onFilter: setShipmentIdFilter,
        filterValue: shipmentIdFilter,
        inputRegexp: /^\d+$/,
      },
    },
  }];

  const setRowsFromShipments = (currentShipments: ShipmentType[]) => {
    const shipmentsRows = currentShipments.map(({ id , status }) => ({
      id: id.toString(),
      number: id,
      status: <StatusSelect statusValue={status} shipmentId={id} updateList={refetch}/>,
    }));

    setRows(shipmentsRows);
  };

  const handleFilterUpdated = ({ status, number }: SelectedFilters) => {
    const valueStatus = status.selected;
    const valueNumber = number.selected;

    setOffset(0);
    if (valueStatus) setStatusFilter(Object.values(DeliveryStatus)[+valueStatus[0]]);
    if (!valueNumber.length) setShipmentIdFilter(null);
  };

  const handleSortingUpdated = (value: SortByProps<any> | null) => {
    setOffset(0);
    const sort = value?.sortOrder.toUpperCase() === SortEnum.Asc ? SortEnum.Asc : SortEnum.Desc;
    setSortOrder(value ? sort : null);
  };

  return (
    <>
      <Title>Cтатусы посылок</Title>
      <TableWrapper>
        <Table
          rows={rows}
          columns={columns}
          verticalAlign='center'
          borderBetweenColumns
          zebraStriped='odd'
          filters={[...statusFilters, ...shipmentIdFilters]}
          onSortBy={handleSortingUpdated}
          onFiltersUpdated={handleFilterUpdated}
        />
      </TableWrapper>
    </>
  );
};

export default StatusesPage;
