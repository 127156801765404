import React, { FC, useEffect, useRef, useState } from 'react';
import { SortByProps, Table, TableFilters } from '@consta/uikit/Table';
import { SelectedFilters } from '@consta/uikit/__internal__/src/components/Table/filtering';
import { EditPackageInput, Maybe, PackageType, SortEnum } from 'types';
import { useEditPackageMutation } from 'pages/packages/api/editPackage.generated';
import { usePackagesListQuery } from 'pages/packages/api/packagesList.generated';
import { showErrorNotification } from 'widgets/notifications/api/showErrorNotification';
import { TableCellPadding } from 'pages/admin/boxes/styled';
import { useIsInViewport } from 'shared/hooks/useIsInViewport';
import TableInput from 'shared/ui/components/tableInput';
import CustomInputTableFilter from 'shared/ui/components/customInputTableFilter';
import { TableWrapper, WeightValues } from '../styled';

interface IProps {
  updateSummary: () => void
  trackSearch?: string
  shipmentId?: number
  activeField?: string
  onChangeComment: () => void
}

interface ISort {
  sortingBy: string,
  sortOrder: SortEnum
}

const ReconciliationTable: FC<IProps> = ({
  trackSearch,
  shipmentId,
  updateSummary,
  activeField,
  onChangeComment,
}) => {
  const pageSize = 40;

  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);
  const [boxIdFilter, setBoxIdFilter] = useState<Maybe<number>>(null);
  const [sort, setSort] = useState<Maybe<ISort>>(null);

  const [editingField, setEditingField] = useState<'actualWeight' | 'comment' | undefined>();
  const [editingPackageId, setEditingPackageId] = useState<number | undefined>(undefined);

  const [rows, setRows] = useState<any[]>([]);
  const [packages, setPackages] = useState<PackageType[]>([]);

  const footerRef = useRef(document.getElementById('footer'));
  const inViewport = useIsInViewport(footerRef);

  const [updatePackage] = useEditPackageMutation();

  const getSortBy = (sortingBy: string) => sort?.sortingBy === sortingBy ? sort.sortOrder : undefined;

  const { data: dataPackages, refetch: updatePackages } = usePackagesListQuery({
    skip: typeof shipmentId !== 'number',
    variables: {
      filter: {
        track: trackSearch,
        shipmentId,
        boxId: boxIdFilter ?? undefined,
      },
      pagination: {
        limit: pageSize,
        offset: offset * pageSize,
        order: {
          approximateWeight: getSortBy('approximateWeight'),
          actualWeight: getSortBy('actualWeight'),
        },
      },
    },
  });

  useEffect(() => {
    updatePackages();
  }, [boxIdFilter, sort]);

  useEffect(() => {
    if (activeField === 'weight') {
      setEditingField('actualWeight');
    }
  }, [activeField]);

  useEffect(() => {
    if (inViewport && ((offset + 1) * pageSize <= total)) {
      setOffset(offset + 1);
    }
  }, [inViewport]);

  useEffect(() => {
    const items = dataPackages?.packagesList.items;

    if (items) {
      let currentPackages: PackageType[] = [];

      if (offset) {
        const newPackages = [...items].filter(shipment => !packages.find(item => shipment.id === item.id));
        currentPackages = [...packages, ...newPackages] as PackageType[];
        setPackages(currentPackages);
      } else {
        currentPackages = items as PackageType[];
        setPackages(currentPackages);
        setTotal(dataPackages?.packagesList?.total);
      }
      setRowsFromPackages(currentPackages);

      if (editingField === 'actualWeight' && activeField === 'weight') {
        setEditingPackageId(dataPackages?.packagesList?.items[0].id);
      }
    }
  }, [dataPackages]);

  const renderEditableCell = ({ id, comment, actualWeight }: PackageType, isWeight = false) => {
    const weightValue = actualWeight?.toString();
    const commentValue = comment?.toString();

    const disabled = !editingPackageId
      || +id !== +editingPackageId
      || (isWeight ? editingField !== 'actualWeight' : editingField !== 'comment');

    const newEditingField = isWeight ? 'actualWeight' : 'comment';

    const handleSave = async (value: string | null, enterClick = false) => {
      if (!editingPackageId) {
        return;
      }

      if (isWeight) {
        if (!value) {
          setEditingPackageId(undefined);
        } else {
          const isValid = /^[1-9]?[0-9]+[,.]?[0-9]?[0-9]?$/.test(value) && value.split(/[.,]/).shift()!.length < 8;
          if (isValid) {
            await saveChanges(+editingPackageId, { actualWeight: parseFloat(value.replace(',', '.')) }, enterClick);
            if (enterClick) {
              setEditingField('comment');
            }
          } else {
            showErrorNotification('Неправильное значение');
          }
        }
      } else {
        const isValid = !value || value.length < 33;
        if (isValid) {
          await saveChanges(+editingPackageId, { comment: value }, enterClick);
        } else {
          showErrorNotification('Неправильное значение');
        }
      }
    };

    const onEnter = (value: string | null) => {
      handleSave(value, true);
    };

    return (
      <TableInput
        inputValue={isWeight ? weightValue : commentValue}
        disabled={disabled}
        onClear={() => setEditingPackageId(undefined)}
        onSave={handleSave}
        onClickEdit={() => {
          setEditingPackageId(id);
          setEditingField(newEditingField);
        }}
        onBlur={() => {
          setEditingPackageId(undefined);
          setEditingField(undefined);
        }}
        onKeyDown={onEnter}
        small
        number={isWeight}
      />
    );
  };

  const columns = [
    {
      title: 'ФАМИЛИЯ ИМЯ ПОЛУЧАТЕЛЯ',
      accessor: 'user',
    },
    {
      title: 'НОМЕР БОКСА',
      accessor: 'box',
      width: 200,
    },
    {
      title: 'ТРЕК-НОМЕР МАГАЗИНА',
      accessor: 'track',
    },
    {
      title: 'ВЕС ИЗНАЧАЛЬНЫЙ',
      accessor: 'approximateWeight',
      sortable: true,
    },
    {
      title: 'ВЕС ФАКТИЧЕСКИЙ',
      accessor: 'actualWeight',
      sortable: true,
      renderCell: (pckg: PackageType) => {
        const { actualWeight, approximateWeight } = pckg;
        return (
          <div className={`weight_wrapper ${getColourClass(approximateWeight, actualWeight)}`}>
            {renderEditableCell(pckg, true)}
          </div>
        );
      },
    },
    {
      title: 'НЕДОВЕС / ПЕРЕВЕС',
      accessor: 'weightDifference',
    },
    {
      title: 'КОММЕНТАРИЙ',
      accessor: 'comment',
      renderCell: renderEditableCell,
    },
  ];

  const saveChanges = async (id: number, input: EditPackageInput, enterClick: boolean) => {
    await updatePackage({
      variables: { id, input },
    }).then(() => {
      if (!enterClick || input?.comment) {
        setEditingField(undefined);
        setEditingPackageId(undefined);
      }
      updateSummary();
      updatePackages().then(() => {
        if (enterClick && input?.comment) {
          onChangeComment();
        }
      });
    });
  };

  const getColourClass = (approximateWeight?: Maybe<number>, actualWeight?: Maybe<number>): string => {
    const weightDifference = approximateWeight && actualWeight
        ? Math.round((actualWeight - approximateWeight) * 10) / 10
        : null;
    return weightDifference !== null ? weightDifference >= 0 ? 'positive_value' : 'negative_value' : '';
  };

  const setRowsFromPackages = (currentBoxes: PackageType[]) => {
    const packagesRows = currentBoxes
      .map(({ id, users, box, track, approximateWeight, actualWeight, comment }) => {
        const user = users.find(u => u.relation === 'RECIPIENT')?.user.name;
        const weightDifference = approximateWeight && actualWeight
          ? Math.round((actualWeight - approximateWeight) * 10) / 10
          : null;
        const weightClass = getColourClass(approximateWeight, actualWeight);

        const weightDifferenceElement = (
          <WeightValues>
            <span className={weightClass}>
              {weightDifference && weightDifference > 0 ? `+${weightDifference}` : weightDifference}
            </span>
          </WeightValues>
        );

        return {
          id: id.toString(),
          user,
          box: box?.number,
          track,
          approximateWeight,
          actualWeight,
          weightDifference: weightDifferenceElement,
          comment,
        };
      });

    setRows(packagesRows);
  };

  const filtersBoxId: TableFilters<typeof rows[number]> = [{
    id: 'box',
    name: 'Номер бокса: ' + boxIdFilter,
    filterer: () => true,
    field: 'box',
    component: {
      name: CustomInputTableFilter,
      props: {
        placeholder: 'Номер бокса',
        onFilter: setBoxIdFilter,
        filterValue: boxIdFilter,
        inputRegexp: /^\d+$/,
      },
    },
  }];

  const handleFilterUpdated = ({ box }: SelectedFilters) => {
    const valueBox = box?.value;

    setOffset(0);

    if (valueBox) setBoxIdFilter(+valueBox);
    else setBoxIdFilter(null);
  };

  const handleSortingUpdated = (value: SortByProps<any> | null) => {
    setOffset(0);

    const sortOrder = value?.sortOrder.toUpperCase() === SortEnum.Asc ? SortEnum.Asc : SortEnum.Desc;
    setSort(value ? {sortingBy: value.sortingBy.toString(), sortOrder} : null);
  };

  return (
    <TableWrapper>
      <TableCellPadding>
        <Table
          rows={rows}
          columns={columns}
          borderBetweenColumns
          zebraStriped='odd'
          filters={filtersBoxId}
          onFiltersUpdated={handleFilterUpdated}
          onSortBy={handleSortingUpdated}
          verticalAlign='center'
        />
      </TableCellPadding>
    </TableWrapper>
  );
};

export default ReconciliationTable;
