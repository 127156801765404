import React, { FC, useEffect, useState, useRef } from 'react';
import dayjs from 'dayjs';
import {
  Table,
  TableFilterContainer,
  TableFilters,
  TableNumberFilter,
  TableTextFilter,
  TableChoiceGroupFilter,
} from '@consta/uikit/Table';
import { IconSearch } from '@consta/uikit/IconSearch';
import { Badge } from '@consta/uikit/Badge';
import { Checkbox } from '@consta/uikit/Checkbox';
import { Combobox } from '@consta/uikit/Combobox';
import { TextField } from '@consta/uikit/TextField';
import { 
  PackagesListFilterInput,
  PackagesListOrderInput,
  PaymentType,
  PackageType,
  PackagesOnUsersRelation,
  DeliveryStatus,
  SortEnum,
} from 'types';
import { usePackagesListQuery } from 'pages/packages/api/packagesList.generated';
import { useStocksListQuery } from 'pages/packagesAdd/api/stocksList.generated';
import { NameBlock } from 'pages/packages/styled';
import { menu } from 'pages/packages';
import { useIsInViewport } from 'shared/hooks/useIsInViewport';
import { TableCellPadding } from '../boxes/styled';

import '../table.css';

type Item = {
  label: string;
  id: string;
};

const Packages: FC = () => {
  const pageSize = 40;
  const [offset, setOffset] = useState(0);

  const [filter, setFilter] = useState<PackagesListFilterInput>({});
  const [filterCopy, setFilterCopy] = useState<PackagesListFilterInput>({});
  const [sortOrder, setSortOrder] = useState<PackagesListOrderInput>({ createdAt: SortEnum.Desc });

  const [packages, setPackages] = useState<any[]>([]);
  const [total, setTotal] = useState(0);

  const footerRef = useRef(document.getElementById('footer'));
  const inViewport = useIsInViewport(footerRef);

  const { data, refetch } = usePackagesListQuery({
    variables: {
      filter: filter || {},
      pagination: {
        limit: pageSize,
        offset: offset * pageSize,
        order: sortOrder || {},
      },
    },
  });

  const { data: stocks } = useStocksListQuery();

  useEffect(() => {
    if (data?.packagesList?.items) {
      if (offset) {
        setPackages([...packages, ...data.packagesList.items]);
      } else {
        setPackages(data.packagesList.items);
        setTotal(data.packagesList.total);
      }
    }
  }, [data]);

  useEffect(() => {
    if (inViewport && ((offset + 1) * pageSize <= total)) {
      setOffset(offset + 1);
    }
  }, [inViewport]);

  useEffect(() => {
    updateList();
  }, [filter, offset, sortOrder]);

  const updateList = () => {
    refetch({
      filter: filter || {},
      pagination: {
        limit: pageSize,
        offset: offset * pageSize,
        order: sortOrder || {},
      },
    });
  };

  const columns = [
    {
      title: 'ТРЕК-НОМЕР МАГАЗИНА',
      accessor: 'track',
      sortable: true,
      width: 260,
    },
    {
      title: 'ТРЕК-НОМЕР UCS',
      accessor: 'id',
      sortable: true,
      width: 130,
    },
    // TODO: sort
    {
      title: 'НОМЕР ПАРТИИ',
      accessor: 'shipmentId',
      sortable: false,
      width: 130,
      renderCell: ({ box }: PackageType) => box?.shipmentId,
    },
    // TODO: sort, filter (number)
    {
      title: 'НОМЕР БОКСА',
      accessor: 'boxNumber',
      sortable: false,
      width: 100,
      renderCell: ({ box }: PackageType) => box?.number,
    },
    // TODO: sort
    {
      title: 'НОМЕР ЗАКАЗА',
      accessor: 'orderId',
      sortable: true,
      width: 100,
    },
    {
      title: 'СКЛАД',
      accessor: 'stock',
      sortable: false,
      width: 100,
      renderCell: ({ stock }: PackageType) => {
        if (stock?.code === 'eu') {
          return 'Европа';
        } else if (stock?.code === 'us') {
          return 'США';
        }
        return '';
      },
    },
    // TODO: sort, filter
    {
      title: 'ФИО ПОЛУЧАТЕЛЯ',
      accessor: 'userName',
      sortable: false,
      width: 200,
      renderCell: ({ users }: PackageType) => {
        return users.find(u => u.relation === PackagesOnUsersRelation.Recipient)?.user.name;
      },
    },
    // TODO: в макете не видно, но скорее всего фильтр
    {
      title: 'СТАТУС',
      accessor: 'status',
      sortable: false,
      width: 200,
      renderCell: ({ status }: PackageType) => (
        <>{status && <Badge status='normal' label={menu[status]} />}</>
      ),
    },
    // TODO: filter
    {
      title: 'ОПИСАНИЕ ПОСЫЛКИ',
      accessor: 'description',
      sortable: false,
      width: 300,
    },
    {
      title: 'ВЕС',
      accessor: 'clientWeight',
      sortable: true,
      width: 100,
    },
    {
      title: 'ТАРИФ',
      accessor: 'deliveryRate',
      sortable: true,
      width: 100,
    },
    {
      title: 'СТРАХОВКА',
      accessor: 'insuredPrice',
      sortable: true,
      width: 160,
    },
    {
      title: 'СТОИМОСТЬ $',
      accessor: 'costUsd',
      sortable: true,
      width: 160,
      renderCell: ({ totalCost }: PackageType) => (
        <>{ totalCost?.costUsd }</>
      ),
    },
    {
      title: 'СТОИМОСТЬ РУБ',
      accessor: 'costRub',
      sortable: true,
      width: 160,
      renderCell: ({ totalCost }: PackageType) => (
        <>{ totalCost?.costRub }</>
      ),
    },
    // TODO: sort, filter для всех четырхе оплат
    {
      title: 'ОПЛАТИЛИ $',
      accessor: 'usdCash',
      sortable: false,
      width: 160,
      renderCell: ({ order, totalCost }: PackageType) => {
        return order?.paymentType && order.paymentType === PaymentType.UsdCash && (
          <>{ totalCost?.costUsd }</>
        );
      },
    },
    {
      title: 'ОПЛАТИЛИ РОБОКАССА',
      accessor: 'PaymentGateway',
      sortable: false,
      width: 160,
      renderCell: ({ order, totalCost }: PackageType) => {
        return order?.paymentType && order.paymentType === PaymentType.PaymentGateway && (
          <>{ totalCost?.costRub }</>
        );
      },
    },
    {
      title: 'ОПЛАТИЛИ НА КАРТУ',
      accessor: 'BankTransfer',
      sortable: false,
      width: 160,
      renderCell: ({ order, totalCost }: PackageType) => {
        return order?.paymentType && order.paymentType === PaymentType.BankTransfer && (
          <>{ totalCost?.costRub }</>
        );
      },
    },
    {
      title: 'ОПЛАТИЛИ НАЛИЧНЫМИ РУБ',
      accessor: 'RubCash',
      sortable: false,
      width: 160,
      renderCell: ({ order, totalCost }: PackageType) => {
        return order?.paymentType && order.paymentType === PaymentType.RubCash && (
          <>{ totalCost?.costRub }</>
        );
      },
    },
    // TODO: filter
    {
      title: 'КОММЕНТАРИЙ',
      accessor: 'comment',
      sortable: false,
      width: 200,
    },
    // TODO: filter
    {
      title: 'АДРЕС ПОЛУЧЕНИЯ',
      accessor: 'address',
      sortable: false,
      width: 340,
      renderCell: ({ address }: PackageType) => address?.address || 'Самовывоз',
    },
    // TODO: filter
    {
      title: 'ID ПОЛУЧАТЕЛЯ',
      accessor: 'recipientId',
      sortable: false,
      width: 150,
      renderCell: ({ users }: PackageType) => {
        return users.find(u => u.relation === PackagesOnUsersRelation.Recipient)?.userId;
      },
    },
    // TODO: filter
    {
      title: 'ПОСЫЛКА ПЕРЕДАНА',
      accessor: 'isSended',
      sortable: false,
      width: 180,
      renderCell: ({ users }: PackageType) => (
        <Checkbox
          checked={users[0]?.userId !== users[1]?.userId}
          disabled
        />
      ),
    },
    // TODO: filter
    {
      title: 'ID ОТПРАВИТЕЛЯ',
      accessor: 'senderId',
      sortable: false,
      width: 150,
      renderCell: ({ users }: PackageType) => {
        return users.find(u => u.relation === PackagesOnUsersRelation.Creator)?.userId;
      },
    },
    // TODO: filter
    {
      title: 'ДАТА ДОБАВЛЕНИЯ',
      accessor: 'createdAt',
      sortable: true,
      width: 200,
      renderCell: ({ createdAt }: PackageType) => dayjs(createdAt).format('DD.MM.YYYY'),
    },
  ];

  const handleSortingUpdated = (value) => {
    setOffset(0);

    let sortVal: string = value?.sortingBy;

    if (['costUsd', 'costRub'].includes(value?.sortingBy)) {
      sortVal = 'deliveryCost';
    }

    if (sortVal) {
      const sort = {
        [sortVal]: value?.sortOrder.toUpperCase(),
      };
      setSortOrder(sort);
    } else {
      setSortOrder({ createdAt: SortEnum.Desc });
    }
  };

  const handleFilterUpdated = (value) => {
    setOffset(0);

    const filtersObj = { ...filter };

    Object.keys(value).forEach(item => {
      if (value[item]?.selected?.length) {
        if (item === 'deliveryRate') {
          filtersObj[item] = {
            min: value[item]?.value?.min ? parseFloat(value[item]?.value?.min) : undefined,
            max: value[item]?.value?.max ? parseFloat(value[item]?.value?.max) : undefined,
          };
        } else if (item === 'status') {
          filtersObj.statuses = value[item]?.value?.map(i => i?.value);
        } else if (item === 'stock') {
          filtersObj.stockId = value[item]?.value?.value;
        } else {
          filtersObj[item] = filterCopy[item];
        }
      }

      const itemName = item === 'status' ? 'statuses' : item === 'stock' ? 'stockId' : item;

      if (filter && filter[itemName] && !value[item]?.selected?.length) {
        filtersObj[itemName] = undefined;
      }
    });

    setFilter(filtersObj);
    setFilterCopy(filtersObj);
  };

  const filters: TableFilters<any> = [
    {
      id: 'track',
      name: `Трек-номер магазина: ${filter?.track}`,
      field: 'track',
      filterer: () => true,
      component: {
        name: TableFilterContainer as any,
        props: {
          children: (
            <TextField
              value={filterCopy?.track}
              onChange={({ value }) => setFilterCopy({ ...filterCopy, track: value })}
              width='full'
            />
          ),
        },
      },
    },
    {
      id: 'shipmentId',
      name: `Номер посылки: ${filter?.shipmentId}`,
      field: 'shipmentId',
      filterer: () => true,
      component: {
        name: TableFilterContainer as any,
        props: {
          children: (
            <TextField
              value={filterCopy?.shipmentId?.toString() || null}
              onChange={({ value }) => value && setFilterCopy({ ...filterCopy, shipmentId: parseInt(value) })}
              width='full'
              type='number'
              incrementButtons={false}
            />
          ),
        },
      },
    },
    {
      id: 'orderId',
      name: `Номер заказа: ${filter?.orderId}`,
      field: 'orderId',
      filterer: () => true,
      component: {
        name: TableFilterContainer as any,
        props: {
          children: (
            <TextField
              value={filterCopy?.orderId?.toString() || null}
              onChange={({ value }) => value && setFilterCopy({ ...filterCopy, orderId: parseInt(value) })}
              width='full'
              type='number'
              incrementButtons={false}
            />
          ),
        },
      },
    },
    {
      id: 'deliveryRate',
      name: 'Тариф: ',
      field: 'deliveryRate',
      filterer: () => true,
      component: {
        name: TableNumberFilter,
      },
    },
    {
      id: 'status',
      name: 'Статус: ',
      field: 'status',
      filterer: () => true,
      component: {
        name: TableTextFilter,
        props: {
          withSearch: false,
          items: [
            { name: menu[DeliveryStatus.UsaStock], value: DeliveryStatus.UsaStock },
            { name: menu[DeliveryStatus.Sent], value: DeliveryStatus.Sent },
            { name: menu[DeliveryStatus.Customs], value: DeliveryStatus.Customs },
            { name: menu[DeliveryStatus.RuStok], value: DeliveryStatus.RuStok },
            { name: menu[DeliveryStatus.ReadyForDelivery], value: DeliveryStatus.ReadyForDelivery },
            { name: menu[DeliveryStatus.Delivered], value: DeliveryStatus.Delivered },
          ],
        },
      },
    },
    {
      id: 'description',
      name: `Описание: ${filter?.description}`,
      field: 'description',
      filterer: () => true,
      component: {
        name: TableFilterContainer as any,
        props: {
          children: (
            <TextField
              value={filterCopy?.description}
              onChange={({ value }) => setFilterCopy({ ...filterCopy, description: value })}
              width='full'
            />
          ),
        },
      },
    },
    {
      id: 'comment',
      name: `Комментарий: ${filter?.comment}`,
      field: 'comment',
      filterer: () => true,
      component: {
        name: TableFilterContainer as any,
        props: {
          children: (
            <TextField
              value={filterCopy?.comment}
              onChange={({ value }) => setFilterCopy({ ...filterCopy, comment: value })}
              width='full'
            />
          ),
        },
      },
    },
    {
      id: 'userName',
      name: `Комментарий: ${filter?.userName}`,
      field: 'userName',
      filterer: () => true,
      component: {
        name: TableFilterContainer as any,
        props: {
          children: (
            <TextField
              value={filterCopy?.userName}
              onChange={({ value }) => setFilterCopy({ ...filterCopy, userName: value })}
              width='full'
            />
          ),
        },
      },
    },
    {
      id: 'stock',
      name: 'Склад: ',
      field: 'stock',
      filterer: () => true,
      component: {
        name: TableChoiceGroupFilter,
        props: {
          items: stocks?.stocksList?.map(stock => ({
            name: stock?.code === 'eu' ? 'Европа' : stock?.code === 'us' ? 'США' : '',
            value: stock.id,
          })),
        },
      },
    },
  ];

  const [hiddenFields, setHiddenFields] = useState<Item[] | null>([]);

  const hiddenOptions: Item[] = [
    {
      id: 'track',
      label: 'Трек-номер магазина',
    },
    {
      id: 'id',
      label: 'Трек-номер UCS',
    },
    {
      id: 'shipmentId',
      label: 'Номер партии',
    },
    {
      id: 'boxNumber',
      label: 'Номер бокса',
    },
    {
      id: 'orderId',
      label: 'Номер заказа',
    },
    {
      id: 'userName',
      label: 'ФИО получателя',
    },
    {
      id: 'status',
      label: 'Статус',
    },
    {
      id: 'description',
      label: 'Описание посылки',
    },
    {
      id: 'clientWeight',
      label: 'Вес',
    },
    {
      id: 'deliveryRate',
      label: 'Тариф',
    },
  ];

  const [columnsState, setColumnsState] = useState<any[]>(columns);

  useEffect(() => {
    setColumnsState(columnsState.map(item => {
      if (hiddenFields && hiddenFields.find(el => el.id === item.accessor)) {
        return { ...item, hidden: true };
      }
      return { ...item, hidden: false };
    }));
  }, [hiddenFields]);
  
  return (
    <>
      <NameBlock>
        <h1>
          Посылки
        </h1>
        <Combobox
          placeholder='Скрыть поля'
          items={hiddenOptions}
          value={hiddenFields}
          onChange={({ value }) => setHiddenFields(value)}
          multiple
        />
        <TextField
          size='m'
          placeholder='Поиск'
          value={filter.q}
          onChange={({ value }) => {
            setFilter({ ...filter, q: value });
            setFilterCopy({ ...filterCopy, q: value });
          }}
          leftSide={IconSearch}
          withClearButton
        />
      </NameBlock>
      <TableCellPadding>
        {/* @ts-ignore */}
        <Table rows={packages} columns={columnsState} filters={filters}
          verticalAlign='center'
          borderBetweenColumns
          zebraStriped='odd'
          defaultExpandAll
          stickyHeader
          onSortBy={(value) => handleSortingUpdated(value)}
          onFiltersUpdated={(value) => handleFilterUpdated(value)}
        />
      </TableCellPadding>
    </>
  );
};

export default Packages;