import React, { FC } from 'react';
import { Modal } from '@consta/uikit/Modal';
import { Button } from '@consta/uikit/Button';
import { ModalContent } from './styled';
import './modal.css';

interface dataFormProps {
  title: string,
  isOpen: boolean,
  onClose: () => void,
  onClick: () => void,
};

const ApproveModal: FC<dataFormProps> = ({ title, isOpen, onClose, onClick }: dataFormProps) => {
  return (
    <Modal
      isOpen={isOpen}
      hasOverlay
      onClickOutside={onClose}
      onEsc={onClose}
      rootClassName='approveModal'
    >
      <ModalContent>
        <div className='title'>{title}</div>
        <div className='buttons'>
          <Button
            label='Удалить'
            form='round'
            onClick={() => {
              onClick();
              onClose();
            }}
            className='deleteButton'
          />
          <Button
            label='Отменить'
            form='round'
            onClick={onClose}
            className='cancelButton'
          />
        </div>
      </ModalContent>
    </Modal>
  );
};

export default ApproveModal;
