import { Navigate, useLocation } from 'react-router-dom';
import { Loader } from '@consta/uikit/Loader';
import { useAdmin } from 'entities/admin';
import { getAuthToken } from 'shared/api/auth';

function RedirectToLogin() {
  const location = useLocation();

  return <Navigate to='/login' state={{ from: location }} replace />;
}

export function RequireAuth({ children }: { children: JSX.Element }) {
  const { isAuth, loading } = useAdmin();
  const token = getAuthToken();

  return isAuth ? children : (loading || token ? <Loader /> : <RedirectToLogin />);
}

function RedirectToMainPage() {
  const location = useLocation();

  return <Navigate to='/users' state={{ from: location }} replace />;
}

export function RequireNotAuth({ children }: { children: JSX.Element }) {
  const { isAuth, loading } = useAdmin();
  const token = getAuthToken();

  return isAuth ? <RedirectToMainPage /> : (loading || token ? <Loader /> : children);
}
