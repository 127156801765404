import React, { FC, useState, useEffect } from 'react';
import { Table, TableFilterContainer, TableFilters, TableNumberFilter } from '@consta/uikit/Table';
import { TextField } from '@consta/uikit/TextField';
import { NameBlock } from 'pages/packages/styled';
import {
  UserShipmentPackageType,
  UserShipmentPackagesFilterInput,
  UserShipmentPackagesOrderInput,
  Maybe,
  SortEnum,
} from 'types';
import { showErrorNotification } from 'widgets/notifications/api/showErrorNotification';
import AdminTabs from 'shared/ui/components/adminTabs';
import { UserLink } from 'shared/ui/components/userLink';
import TableInput from 'shared/ui/components/tableInput';
import { TableCellPadding } from '../boxes/styled';
import { useUserShipmentPackagesQuery } from './api/userShipmentPackages.generated';
import { useUpdateShipmentClientWeightMutation } from './api/updateShipmentClientWeight.generated';
import ShipmentInfo from './ui/shipmentInfo';
import PackagesModal from './ui/packagesModal';

import '../table.css';

const Finance: FC = () => {
  const [activeShipment, setActiveShipment] = useState<number | undefined>();

  const onChangeShipment = (value: number) => {
    setActiveShipment(value);
  };

  const [filter, setFilter] = useState<Omit<UserShipmentPackagesFilterInput, 'shipmentId'>>({});
  const [filterCopy, setFilterCopy] = useState<Omit<UserShipmentPackagesFilterInput, 'shipmentId'>>({});
  const [sortOrder, setSortOrder] = useState<UserShipmentPackagesOrderInput>({ recipientName: SortEnum.Asc });

  const { data, refetch } = useUserShipmentPackagesQuery({
    skip: typeof activeShipment !== 'number',
    variables: {
      filter: {
        shipmentId: activeShipment as number,
      },
      order: sortOrder,
    },
  });

  useEffect(() => {
    updateList();
  }, [filter, sortOrder]);

  const updateList = async () => {
    if (typeof activeShipment === 'number') {
      await refetch({
        filter: { shipmentId: activeShipment, ...(filter || {}) },
        order: {
          ...(sortOrder || {}),
        },
      });
    }
  };

  const [editingUserShipmentId, setEditingUserShipmentId] = useState<number | undefined>();
  const [updateShipmentClientWeight, {data: dataEdit, error: errorEdit}] = useUpdateShipmentClientWeightMutation();

  const saveUserShipmentChanges = async (value: string | null) => {
    const editingUserShipmentWeight = value ? parseFloat(value) : NaN;

    if (typeof editingUserShipmentId === 'number' && !isNaN(editingUserShipmentWeight)) {
      await updateShipmentClientWeight({
        variables: {
          id: activeShipment as number,
          input: {
            userId: editingUserShipmentId,
            clientWeight: editingUserShipmentWeight,
          },
        },
      });
    } else {
      showErrorNotification('Поле не может быть пустым');
    }
  };

  useEffect(() => {
    if (dataEdit?.updateShipmentClientWeight && !errorEdit) {
      setEditingUserShipmentId(undefined);

      refetch();
    }
  }, [dataEdit, errorEdit]);

  const [recipientId, setRecipientId] = useState<number | undefined>();

  const columns = [
    {
      title: 'ФИО',
      accessor: 'recipientName',
      sortable: true,
      renderCell: ({ recipientId: rId, recipientName }) => (
        <UserLink onClick={() => setRecipientId(rId)}>{recipientName}</UserLink>
      ),
    },
    {
      title: 'КОЛИЧЕСТВО',
      accessor: 'count',
      sortable: true,
    },
    {
      title: 'ОБЩИЙ ВЕС ПОСЫЛОК',
      accessor: 'actualWeight',
      sortable: true,
    },
    {
      title: 'ИТОГОВЫЙ ВЕС С УПАКОВКОЙ',
      accessor: 'clientWeight',
      sortable: true,
      renderCell: ({ recipientId: rId, clientWeight, actualWeight }: UserShipmentPackageType) => {
        if (actualWeight) {
          return (
            <TableInput
              inputValue={clientWeight?.toString()}
              disabled={rId !== editingUserShipmentId}
              onClear={() => setEditingUserShipmentId(undefined)}
              onSave={saveUserShipmentChanges}
              onClickEdit={() => {
                setEditingUserShipmentId(rId);
              }}
              onBlur={() => setEditingUserShipmentId(undefined)}
              small
              number
            />
          );
        }
      },
    },
    {
      title: 'СТРАХОВКА ОБЩАЯ',
      accessor: 'insuranceCost',
      sortable: true,
    },
    {
      title: 'СУММА $',
      accessor: 'costUsd',
      sortable: true,
      renderCell: ({ totalCost }: UserShipmentPackageType) => (
        <>{ totalCost?.costUsd }</>
      ),
    },
    {
      title: 'СУММА РУБ',
      accessor: 'costRub',
      sortable: true,
      renderCell: ({ totalCost }: UserShipmentPackageType) => (
        <>{ totalCost?.costRub }</>
      ),
    },
  ];

  const handleSortingUpdated = (value: any) => {
    let sortVal: string = value?.sortingBy;

    if (['costUsd', 'costRub'].includes(value?.sortingBy)) {
      sortVal = 'deliveryCost';
    }

    if (sortVal) {
      const sort = {
        [sortVal]: value?.sortOrder.toUpperCase(),
      };
      setSortOrder(sort);
    }
  };

  const handleFilterUpdated = (value: any) => {
    const filtersObj = { ...filter };

    Object.keys(value).forEach(item => {
      const filterVal = item === 'recipientName' ? 'name' : item;
      if (value[item]?.selected?.length) {
        if (item === 'insuranceCost') {
          filtersObj[filterVal] = {
            min: value[item]?.value?.min ? parseFloat(value[item]?.value?.min) : undefined,
            max: value[item]?.value?.max ? parseFloat(value[item]?.value?.max) : undefined,
          };
        } else {
          filtersObj[filterVal] = filterCopy[filterVal];
        }
      }

      if (filter && filter[filterVal] && !value[item]?.selected?.length) {
        filtersObj[filterVal] = undefined;
      }
    });

    setFilter(filtersObj);
    setFilterCopy(filtersObj);
  };

  const filters: TableFilters<any> = [
    {
      id: 'recipientName',
      name: `ФИО: ${filter?.name}`,
      field: 'recipientName',
      filterer: () => true,
      component: {
        name: TableFilterContainer as any,
        props: {
          children: (
            <TextField
              value={filterCopy?.name}
              onChange={({ value }) => setFilterCopy({ ...filterCopy, name: value })}
              width='full'
            />
          ),
        },
      },
    },
    {
      id: 'insuranceCost',
      name: 'Страховка: ',
      field: 'insuranceCost',
      filterer: () => true,
      component: {
        name: TableNumberFilter,
      },
    },
  ];

  return (
    <>
      <NameBlock>
        <h1>
          Финансы
        </h1>
      </NameBlock>
      <AdminTabs onChange={onChangeShipment} />
      {typeof activeShipment === 'number' && <ShipmentInfo shipmentId={activeShipment} onChange={refetch} />}
      {(typeof activeShipment === 'number' && recipientId) && <PackagesModal
        shipmentId={activeShipment}
        userId={recipientId}
        isOpen={!!(typeof activeShipment === 'number' && recipientId)}
        onClose={() => setRecipientId(undefined)}
      />}
      <TableCellPadding>
        {/* @ts-ignore */}
        <Table rows={data?.userShipmentPackages || []} columns={columns} filters={filters}
          verticalAlign='center'
          borderBetweenColumns
          zebraStriped='odd'
          defaultExpandAll
          onSortBy={(value) => handleSortingUpdated(value)}
          onFiltersUpdated={(value) => handleFilterUpdated(value)}
        />
      </TableCellPadding>
    </>
  );
};

export default Finance;