import React, { FC, useState } from 'react';
import { Button } from '@consta/uikit/Button';
import { IconClose } from '@consta/uikit/IconClose';
import { CounterBlock } from 'pages/packages/ui/counter/styled';
import ApproveModal from '../approveModal';

interface dataFormProps {
  ids: number[],
  onClose: () => void,
  onClick: () => void,
};

const BoxesCounter: FC<dataFormProps> = ({ ids, onClose, onClick }: dataFormProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isHiddenCounter, setIsHiddenCounter] = useState(false);

  const onDelete = () => {
    setIsHiddenCounter(true);
    setIsModalOpen(true);
  };

  return (
    <>
      {ids?.length && !isHiddenCounter && (
        <CounterBlock>
          <Button
            className='close'
            label='Закрыть'
            view='clear'
            iconLeft={IconClose}
            onlyIcon
            size='s'
            onClick={onClose}
          />
          <p>Количество выбранных боксов: {ids.length}</p>
          <Button
            className='delete'
            label='Удалить'
            size='s'
            form='round'
            onClick={onDelete}
          />
        </CounterBlock>
      ) || null}
      <ApproveModal
        title='Вы точно хотите удалить боксы? '
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setIsHiddenCounter(false);
        }}
        onClick={onClick}
      />
    </>
  );
};

export default BoxesCounter;
